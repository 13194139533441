
import BaseTab from "@/components/Base/BaseTab.vue";
import { helperMixin } from "@/mixins";
import { defineComponent, shallowRef } from "vue";
import CrewOnBoard from "@/components/Planning/CrewOnBoard.vue";
import { FleetDetail } from "@/models/fleet";
import fleetHttp from "@/http/fleet";
import { Tab } from "@/interfaces";

export default defineComponent({
  mixins: [helperMixin],
  components: { BaseTab, CrewOnBoard },
  data() {
    return {
      fleet: {} as FleetDetail,
      tabs: [] as Tab[]
    };
  },
  async mounted() {
    this.fleet = (
      await fleetHttp().read(this.currentRoute.params?.id)
    ).response.data;

    this.tabs = [
      {
        title: this.trans("layout.fleet.crew_on_board"),
        isDefault: true,
        component: shallowRef(CrewOnBoard),
        componentProps: {
          fleetId: this.currentRoute.params?.id
        }
      }
    ];
  }
});
