import { createInstance } from "@/lib/fn";
import { FleetPaginate } from "@/models/fleet";
import BaseResourceHttp from "@/lib/resourceHttp";

class Fleet extends BaseResourceHttp<FleetPaginate, any, any> {
    protected baseURL(): string {
        return "fleet";
    }
}

export default createInstance<Fleet>(Fleet);