import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_table_column = _resolveComponent("base-table-column")!
  const _component_action_table_column = _resolveComponent("action-table-column")!
  const _component_base_table_row = _resolveComponent("base-table-row")!
  const _component_crew_on_board_row = _resolveComponent("crew-on-board-row")!
  const _component_base_table = _resolveComponent("base-table")!
  const _component_switch_crew_modal = _resolveComponent("switch-crew-modal")!
  const _component_renew_crew_signoff_modal = _resolveComponent("renew-crew-signoff-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_base_table, {
      onLoadMore: _ctx.loadMore,
      loading: _ctx.loading,
      hasMore: _ctx.hasMore,
      noDataAvailable: _ctx.noDataAvailable,
      noResultFound: _ctx.noResultFound,
      class: _normalizeClass(!_ctx.fleetTabs.length ? 'tabs-0' : ''),
      colspan: "10"
    }, {
      header: _withCtx(() => [
        _createVNode(_component_base_table_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_base_table_column, {
              width: "300",
              colspan: "2",
              type: "label"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans("layout.user.fullname")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_base_table_column, { type: "label" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans("layout.user.age")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_base_table_column, { type: "label" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans("layout.user.gender")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_base_table_column, { type: "label" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans("layout.user.nationality")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_base_table_column, { type: "label" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans("layout.job.position_id")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_base_table_column, { type: "label" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans("layout.crew.status")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_base_table_column, { type: "label" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans("layout.crew.sign_on")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_base_table_column, { type: "label" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans("layout.crew.sign_off")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_base_table_column, { type: "label" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans("layout.crew.readliness")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_base_table_column, { type: "label" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans("layout.fleet.ship_name")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_action_table_column, { type: "label" })
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row, i) => {
          return (_openBlock(), _createBlock(_component_crew_on_board_row, {
            key: i,
            row: row,
            onRenewCrewSignoff: _ctx.renewCrewSignOff,
            onSwitchCrew: _ctx.switchCrew
          }, null, 8, ["row", "onRenewCrewSignoff", "onSwitchCrew"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["onLoadMore", "loading", "hasMore", "noDataAvailable", "noResultFound", "class"]),
    (_ctx.selectedCrewBoard)
      ? (_openBlock(), _createBlock(_component_switch_crew_modal, {
          key: 0,
          onSaved: _ctx.reload,
          modelValue: _ctx.showSwitchCrewModal,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showSwitchCrewModal) = $event)),
          crewBoard: _ctx.selectedCrewBoard
        }, null, 8, ["onSaved", "modelValue", "crewBoard"]))
      : _createCommentVNode("", true),
    (_ctx.selectedCrewBoard)
      ? (_openBlock(), _createBlock(_component_renew_crew_signoff_modal, {
          key: 1,
          onSaved: _ctx.reload,
          modelValue: _ctx.showRenewCrewSignOffModal,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showRenewCrewSignOffModal) = $event)),
          crewBoard: _ctx.selectedCrewBoard
        }, null, 8, ["onSaved", "modelValue", "crewBoard"]))
      : _createCommentVNode("", true)
  ], 64))
}