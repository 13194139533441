
import { Tab } from "@/interfaces";
import { useRouter } from "@/lib/router";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    tabs: {
      type: Array as PropType<import("@/interfaces").Tab[]>,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      currentTab: null as null | undefined | Tab
    };
  },
  mounted() {
    if (this.$route.query.tab) {
      this.currentTab = this.tabs.find(
        tab => tab.queryString === this.$route.query.tab
      );
    } else {
      this.currentTab = this.tabs.find(tab => !!tab.isDefault);
    }
  },
  methods: {
    tabActiveClass(tab: Tab) {
      return this.currentTab?.title === tab.title ? "is-active" : "";
    },
    async onTabClick(tab: Tab) {
      if (this.loading) return;

      this.loading = true;
      this.currentTab = null;

      await this.$nextTick();

      this.currentTab = tab;
      this.loading = false;
    }
  },
  watch: {
    currentTab(tab: Tab) {
      if (tab) {
        useRouter().replace({
          query: {
            ...this.$route.query,
            tab: tab.queryString || undefined
          }
        });
      }
    }
  }
});
