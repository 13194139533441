import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4510dcc1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "uwu-tab-header" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 0,
  class: "uwu-tab-body"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, i) => {
        return (_openBlock(), _createElementBlock("a", {
          href: "javascript:;",
          class: _normalizeClass(["uwu-tab-link", _ctx.tabActiveClass(tab)]),
          key: i,
          onClick: ($event: any) => (_ctx.onTabClick(tab))
        }, _toDisplayString(tab.title), 11, _hoisted_2))
      }), 128))
    ]),
    (_ctx.currentTab)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentTab.component), _normalizeProps(_guardReactiveProps(_ctx.currentTab.componentProps)), null, 16))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}